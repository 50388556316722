import DetectRTC from "detectrtc";

const showRtcNotice = () => {
  DetectRTC.load(function () {
    const supportsRtc =
      DetectRTC.hasWebcam &&
      DetectRTC.hasMicrophone &&
      DetectRTC.isWebRTCSupported;

    if (supportsRtc) {
      return;
    }

    document.getElementById("outer-wrapper").style.opacity = "20%";
    document.getElementById("outdated").style.display = "block";
  });
};

export default showRtcNotice;
