/* rails-erb-loader-dependencies ../config/routes */
var routes = {};

(function() {
    /*
File generated by js-routes 1.4.9
Based on Rails 7.0.8 routes of App::Application
 */

(function() {
  var DeprecatedGlobbingBehavior, NodeTypes, ParameterMissing, ReservedOptions, SpecialOptionsKey, UriEncoderSegmentRegex, Utils, result, root,
    hasProp = {}.hasOwnProperty,
    slice = [].slice;

  root = typeof exports !== "undefined" && exports !== null ? exports : this;

  ParameterMissing = function(message, fileName, lineNumber) {
    var instance;
    instance = new Error(message, fileName, lineNumber);
    if (Object.setPrototypeOf) {
      Object.setPrototypeOf(instance, Object.getPrototypeOf(this));
    } else {
      instance.__proto__ = this.__proto__;
    }
    if (Error.captureStackTrace) {
      Error.captureStackTrace(instance, ParameterMissing);
    }
    return instance;
  };

  ParameterMissing.prototype = Object.create(Error.prototype, {
    constructor: {
      value: Error,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });

  if (Object.setPrototypeOf) {
    Object.setPrototypeOf(ParameterMissing, Error);
  } else {
    ParameterMissing.__proto__ = Error;
  }

  NodeTypes = {"GROUP":1,"CAT":2,"SYMBOL":3,"OR":4,"STAR":5,"LITERAL":6,"SLASH":7,"DOT":8};

  DeprecatedGlobbingBehavior = false;

  SpecialOptionsKey = "_options";

  UriEncoderSegmentRegex = /[^a-zA-Z0-9\-\._~!\$&'\(\)\*\+,;=:@]/g;

  ReservedOptions = ['anchor', 'trailing_slash', 'subdomain', 'host', 'port', 'protocol'];

  Utils = {
    configuration: {
      prefix: "",
      default_url_options: {},
      special_options_key: "_options",
      serializer: null
    },
    default_serializer: function(object, prefix) {
      var element, i, j, key, len, prop, s;
      if (prefix == null) {
        prefix = null;
      }
      if (object == null) {
        return "";
      }
      if (!prefix && !(this.get_object_type(object) === "object")) {
        throw new Error("Url parameters should be a javascript hash");
      }
      s = [];
      switch (this.get_object_type(object)) {
        case "array":
          for (i = j = 0, len = object.length; j < len; i = ++j) {
            element = object[i];
            s.push(this.default_serializer(element, prefix + "[]"));
          }
          break;
        case "object":
          for (key in object) {
            if (!hasProp.call(object, key)) continue;
            prop = object[key];
            if ((prop == null) && (prefix != null)) {
              prop = "";
            }
            if (prop != null) {
              if (prefix != null) {
                key = prefix + "[" + key + "]";
              }
              s.push(this.default_serializer(prop, key));
            }
          }
          break;
        default:
          if (object != null) {
            s.push((encodeURIComponent(prefix.toString())) + "=" + (encodeURIComponent(object.toString())));
          }
      }
      if (!s.length) {
        return "";
      }
      return s.join("&");
    },
    serialize: function(object) {
      var custom_serializer;
      custom_serializer = this.configuration.serializer;
      if ((custom_serializer != null) && this.get_object_type(custom_serializer) === "function") {
        return custom_serializer(object);
      } else {
        return this.default_serializer(object);
      }
    },
    clean_path: function(path) {
      var last_index;
      path = path.split("://");
      last_index = path.length - 1;
      path[last_index] = path[last_index].replace(/\/+/g, "/");
      return path.join("://");
    },
    extract_options: function(number_of_params, args) {
      var last_el, options;
      last_el = args[args.length - 1];
      if ((args.length > number_of_params && last_el === void 0) || ((last_el != null) && "object" === this.get_object_type(last_el) && !this.looks_like_serialized_model(last_el))) {
        options = args.pop() || {};
        delete options[this.configuration.special_options_key];
        return options;
      } else {
        return {};
      }
    },
    looks_like_serialized_model: function(object) {
      return !object[this.configuration.special_options_key] && ("id" in object || "to_param" in object);
    },
    path_identifier: function(object) {
      var property;
      if (object === 0) {
        return "0";
      }
      if (!object) {
        return "";
      }
      property = object;
      if (this.get_object_type(object) === "object") {
        if ("to_param" in object) {
          if (object.to_param == null) {
            throw new ParameterMissing("Route parameter missing: to_param");
          }
          property = object.to_param;
        } else if ("id" in object) {
          if (object.id == null) {
            throw new ParameterMissing("Route parameter missing: id");
          }
          property = object.id;
        } else {
          property = object;
        }
        if (this.get_object_type(property) === "function") {
          property = property.call(object);
        }
      }
      return property.toString();
    },
    clone: function(obj) {
      var attr, copy, key;
      if ((obj == null) || "object" !== this.get_object_type(obj)) {
        return obj;
      }
      copy = obj.constructor();
      for (key in obj) {
        if (!hasProp.call(obj, key)) continue;
        attr = obj[key];
        copy[key] = attr;
      }
      return copy;
    },
    merge: function() {
      var tap, xs;
      xs = 1 <= arguments.length ? slice.call(arguments, 0) : [];
      tap = function(o, fn) {
        fn(o);
        return o;
      };
      if ((xs != null ? xs.length : void 0) > 0) {
        return tap({}, function(m) {
          var j, k, len, results, v, x;
          results = [];
          for (j = 0, len = xs.length; j < len; j++) {
            x = xs[j];
            results.push((function() {
              var results1;
              results1 = [];
              for (k in x) {
                v = x[k];
                results1.push(m[k] = v);
              }
              return results1;
            })());
          }
          return results;
        });
      }
    },
    normalize_options: function(parts, required_parts, default_options, actual_parameters) {
      var i, j, key, len, options, part, parts_options, result, route_parts, url_parameters, use_all_parts, value;
      options = this.extract_options(parts.length, actual_parameters);
      if (actual_parameters.length > parts.length) {
        throw new Error("Too many parameters provided for path");
      }
      use_all_parts = actual_parameters.length > required_parts.length;
      parts_options = {};
      for (key in options) {
        if (!hasProp.call(options, key)) continue;
        use_all_parts = true;
        if (this.indexOf(parts, key) >= 0) {
          parts_options[key] = value;
        }
      }
      options = this.merge(this.configuration.default_url_options, default_options, options);
      result = {};
      url_parameters = {};
      result['url_parameters'] = url_parameters;
      for (key in options) {
        if (!hasProp.call(options, key)) continue;
        value = options[key];
        if (this.indexOf(ReservedOptions, key) >= 0) {
          result[key] = value;
        } else {
          url_parameters[key] = value;
        }
      }
      route_parts = use_all_parts ? parts : required_parts;
      i = 0;
      for (j = 0, len = route_parts.length; j < len; j++) {
        part = route_parts[j];
        if (i < actual_parameters.length) {
          if (!parts_options.hasOwnProperty(part)) {
            url_parameters[part] = actual_parameters[i];
            ++i;
          }
        }
      }
      return result;
    },
    build_route: function(parts, required_parts, default_options, route, full_url, args) {
      var options, parameters, result, url, url_params;
      args = Array.prototype.slice.call(args);
      options = this.normalize_options(parts, required_parts, default_options, args);
      parameters = options['url_parameters'];
      result = "" + (this.get_prefix()) + (this.visit(route, parameters));
      url = Utils.clean_path(result);
      if (options['trailing_slash'] === true) {
        url = url.replace(/(.*?)[\/]?$/, "$1/");
      }
      if ((url_params = this.serialize(parameters)).length) {
        url += "?" + url_params;
      }
      url += options.anchor ? "#" + options.anchor : "";
      if (full_url) {
        url = this.route_url(options) + url;
      }
      return url;
    },
    visit: function(route, parameters, optional) {
      var left, left_part, right, right_part, type, value;
      if (optional == null) {
        optional = false;
      }
      type = route[0], left = route[1], right = route[2];
      switch (type) {
        case NodeTypes.GROUP:
          return this.visit(left, parameters, true);
        case NodeTypes.STAR:
          return this.visit_globbing(left, parameters, true);
        case NodeTypes.LITERAL:
        case NodeTypes.SLASH:
        case NodeTypes.DOT:
          return left;
        case NodeTypes.CAT:
          left_part = this.visit(left, parameters, optional);
          right_part = this.visit(right, parameters, optional);
          if (optional && ((this.is_optional_node(left[0]) && !left_part) || ((this.is_optional_node(right[0])) && !right_part))) {
            return "";
          }
          return "" + left_part + right_part;
        case NodeTypes.SYMBOL:
          value = parameters[left];
          delete parameters[left];
          if (value != null) {
            return this.encode_segment(this.path_identifier(value));
          }
          if (optional) {
            return "";
          } else {
            throw new ParameterMissing("Route parameter missing: " + left);
          }
          break;
        default:
          throw new Error("Unknown Rails node type");
      }
    },
    encode_segment: function(segment) {
      return segment.replace(UriEncoderSegmentRegex, function(str) {
        return encodeURIComponent(str);
      });
    },
    is_optional_node: function(node) {
      return this.indexOf([NodeTypes.STAR, NodeTypes.SYMBOL, NodeTypes.CAT], node) >= 0;
    },
    build_path_spec: function(route, wildcard) {
      var left, right, type;
      if (wildcard == null) {
        wildcard = false;
      }
      type = route[0], left = route[1], right = route[2];
      switch (type) {
        case NodeTypes.GROUP:
          return "(" + (this.build_path_spec(left)) + ")";
        case NodeTypes.CAT:
          return "" + (this.build_path_spec(left)) + (this.build_path_spec(right));
        case NodeTypes.STAR:
          return this.build_path_spec(left, true);
        case NodeTypes.SYMBOL:
          if (wildcard === true) {
            return "" + (left[0] === '*' ? '' : '*') + left;
          } else {
            return ":" + left;
          }
          break;
        case NodeTypes.SLASH:
        case NodeTypes.DOT:
        case NodeTypes.LITERAL:
          return left;
        default:
          throw new Error("Unknown Rails node type");
      }
    },
    visit_globbing: function(route, parameters, optional) {
      var left, right, type, value;
      type = route[0], left = route[1], right = route[2];
      value = parameters[left];
      delete parameters[left];
      if (value == null) {
        return this.visit(route, parameters, optional);
      }
      value = (function() {
        switch (this.get_object_type(value)) {
          case "array":
            return value.join("/");
          default:
            return value;
        }
      }).call(this);
      if (DeprecatedGlobbingBehavior) {
        return this.path_identifier(value);
      } else {
        return encodeURI(this.path_identifier(value));
      }
    },
    get_prefix: function() {
      var prefix;
      prefix = this.configuration.prefix;
      if (prefix !== "") {
        prefix = (prefix.match("/$") ? prefix : prefix + "/");
      }
      return prefix;
    },
    route: function(parts_table, default_options, route_spec, full_url) {
      var j, len, part, parts, path_fn, ref, required, required_parts;
      required_parts = [];
      parts = [];
      for (j = 0, len = parts_table.length; j < len; j++) {
        ref = parts_table[j], part = ref[0], required = ref[1];
        parts.push(part);
        if (required) {
          required_parts.push(part);
        }
      }
      path_fn = function() {
        return Utils.build_route(parts, required_parts, default_options, route_spec, full_url, arguments);
      };
      path_fn.required_params = required_parts;
      path_fn.toString = function() {
        return Utils.build_path_spec(route_spec);
      };
      return path_fn;
    },
    route_url: function(route_defaults) {
      var hostname, port, protocol, subdomain;
      if (typeof route_defaults === 'string') {
        return route_defaults;
      }
      hostname = route_defaults.host || Utils.current_host();
      if (!hostname) {
        return '';
      }
      subdomain = route_defaults.subdomain ? route_defaults.subdomain + '.' : '';
      protocol = route_defaults.protocol || Utils.current_protocol();
      port = route_defaults.port || (!route_defaults.host ? Utils.current_port() : void 0);
      port = port ? ":" + port : '';
      return protocol + "://" + subdomain + hostname + port;
    },
    has_location: function() {
      return (typeof window !== "undefined" && window !== null ? window.location : void 0) != null;
    },
    current_host: function() {
      if (this.has_location()) {
        return window.location.hostname;
      } else {
        return null;
      }
    },
    current_protocol: function() {
      if (this.has_location() && window.location.protocol !== '') {
        return window.location.protocol.replace(/:$/, '');
      } else {
        return 'http';
      }
    },
    current_port: function() {
      if (this.has_location() && window.location.port !== '') {
        return window.location.port;
      } else {
        return '';
      }
    },
    _classToTypeCache: null,
    _classToType: function() {
      var j, len, name, ref;
      if (this._classToTypeCache != null) {
        return this._classToTypeCache;
      }
      this._classToTypeCache = {};
      ref = "Boolean Number String Function Array Date RegExp Object Error".split(" ");
      for (j = 0, len = ref.length; j < len; j++) {
        name = ref[j];
        this._classToTypeCache["[object " + name + "]"] = name.toLowerCase();
      }
      return this._classToTypeCache;
    },
    get_object_type: function(obj) {
      if (root.jQuery && (root.jQuery.type != null)) {
        return root.jQuery.type(obj);
      }
      if (obj == null) {
        return "" + obj;
      }
      if (typeof obj === "object" || typeof obj === "function") {
        return this._classToType()[Object.prototype.toString.call(obj)] || "object";
      } else {
        return typeof obj;
      }
    },
    indexOf: function(array, element) {
      if (Array.prototype.indexOf) {
        return array.indexOf(element);
      } else {
        return this.indexOfImplementation(array, element);
      }
    },
    indexOfImplementation: function(array, element) {
      var el, i, j, len, result;
      result = -1;
      for (i = j = 0, len = array.length; j < len; i = ++j) {
        el = array[i];
        if (el === element) {
          result = i;
        }
      }
      return result;
    },
    namespace: function(root, namespace, routes) {
      var index, j, len, part, parts;
      parts = namespace ? namespace.split(".") : [];
      if (parts.length === 0) {
        return routes;
      }
      for (index = j = 0, len = parts.length; j < len; index = ++j) {
        part = parts[index];
        if (index < parts.length - 1) {
          root = (root[part] || (root[part] = {}));
        } else {
          return root[part] = routes;
        }
      }
    },
    configure: function(new_config) {
      return this.configuration = this.merge(this.configuration, new_config);
    },
    config: function() {
      return this.clone(this.configuration);
    },
    make: function() {
      var routes;
      routes = {
// accept_user_invitation => /users/invitation/accept(.:format)
  // function(options)
  accept_user_invitation_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"invitation",false],[2,[7,"/",false],[2,[6,"accept",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// accessibility => /accessibility(.:format)
  // function(options)
  accessibility_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"accessibility",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// admin_discount_code => /admin/discount_codes/:id(.:format)
  // function(id, options)
  admin_discount_code_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"discount_codes",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_discount_codes => /admin/discount_codes(.:format)
  // function(options)
  admin_discount_codes_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"discount_codes",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_district => /admin/districts/:id(.:format)
  // function(id, options)
  admin_district_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"districts",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_districts => /admin/districts(.:format)
  // function(options)
  admin_districts_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"districts",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_domain => /admin/domains/:id(.:format)
  // function(id, options)
  admin_domain_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"domains",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_domains => /admin/domains(.:format)
  // function(options)
  admin_domains_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"domains",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_ed_prep_program => /admin/ed_prep_programs/:id(.:format)
  // function(id, options)
  admin_ed_prep_program_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"ed_prep_programs",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_ed_prep_programs => /admin/ed_prep_programs(.:format)
  // function(options)
  admin_ed_prep_programs_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"ed_prep_programs",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_families => /admin/families(.:format)
  // function(options)
  admin_families_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"families",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_family => /admin/families/:id(.:format)
  // function(id, options)
  admin_family_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"families",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_group => /admin/groups/:id(.:format)
  // function(id, options)
  admin_group_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"groups",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_group_group_user => /admin/groups/:group_id/group_users/:id(.:format)
  // function(group_id, id, options)
  admin_group_group_user_path: Utils.route([["group_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"groups",false],[2,[7,"/",false],[2,[3,"group_id",false],[2,[7,"/",false],[2,[6,"group_users",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// admin_group_group_users => /admin/groups/:group_id/group_users(.:format)
  // function(group_id, options)
  admin_group_group_users_path: Utils.route([["group_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"groups",false],[2,[7,"/",false],[2,[3,"group_id",false],[2,[7,"/",false],[2,[6,"group_users",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// admin_groups => /admin/groups(.:format)
  // function(options)
  admin_groups_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"groups",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_organization => /admin/organizations/:id(.:format)
  // function(id, options)
  admin_organization_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"organizations",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_report => /admin/report(.:format)
  // function(options)
  admin_report_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"report",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_school => /admin/schools/:id(.:format)
  // function(id, options)
  admin_school_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"schools",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_schools => /admin/schools(.:format)
  // function(options)
  admin_schools_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"schools",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_subscription => /admin/subscriptions/:id(.:format)
  // function(id, options)
  admin_subscription_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"subscriptions",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_subscription_plan => /admin/subscription_plans/:id(.:format)
  // function(id, options)
  admin_subscription_plan_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"subscription_plans",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_subscription_plans => /admin/subscription_plans(.:format)
  // function(options)
  admin_subscription_plans_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"subscription_plans",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_subscriptions => /admin/subscriptions(.:format)
  // function(options)
  admin_subscriptions_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"subscriptions",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_team => /admin/teams/:id(.:format)
  // function(id, options)
  admin_team_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"teams",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_teams => /admin/teams(.:format)
  // function(options)
  admin_teams_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"teams",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_technique => /admin/techniques/:id(.:format)
  // function(id, options)
  admin_technique_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"techniques",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_techniques => /admin/techniques(.:format)
  // function(options)
  admin_techniques_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"techniques",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_training => /admin/trainings/:id(.:format)
  // function(id, options)
  admin_training_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"trainings",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_trainings => /admin/trainings(.:format)
  // function(options)
  admin_trainings_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"trainings",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_user => /admin/users/:id(.:format)
  // function(id, options)
  admin_user_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_users => /admin/users(.:format)
  // function(options)
  admin_users_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"users",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_users_import => /admin/users/import(.:format)
  // function(options)
  admin_users_import_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"import",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// allowed_domain => /domains/allowed(.:format)
  // function(options)
  allowed_domain_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"domains",false],[2,[7,"/",false],[2,[6,"allowed",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// archive_admin_organization => /admin/organizations/:id/archive(.:format)
  // function(id, options)
  archive_admin_organization_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"organizations",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"archive",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// archive_admin_user => /admin/users/:id/archive(.:format)
  // function(id, options)
  archive_admin_user_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"archive",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// assignment => /assignment(.:format)
  // function(options)
  assignment_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"assignment",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// buy_subscription => /subscription_plans/:id/buy(.:format)
  // function(id, options)
  buy_subscription_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"subscription_plans",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"buy",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// cancel_user_registration => /users/cancel(.:format)
  // function(options)
  cancel_user_registration_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"cancel",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// catalog => /catalog(.:format)
  // function(options)
  catalog_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"catalog",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// change_password => /profile/password(.:format)
  // function(options)
  change_password_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"profile",false],[2,[7,"/",false],[2,[6,"password",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// confirm_archival_admin_organization => /admin/organizations/:id/archive(.:format)
  // function(id, options)
  confirm_archival_admin_organization_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"organizations",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"archive",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// confirm_archival_admin_user => /admin/users/:id/archive(.:format)
  // function(id, options)
  confirm_archival_admin_user_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"archive",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// contact_lists => /contact_lists(.:format)
  // function(options)
  contact_lists_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"contact_lists",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// cookie_policy => /cookie-policy(.:format)
  // function(options)
  cookie_policy_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"cookie-policy",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// dashboard => /dashboard(.:format)
  // function(options)
  dashboard_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"dashboard",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// delete_account => /profile/delete_account(.:format)
  // function(options)
  delete_account_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"profile",false],[2,[7,"/",false],[2,[6,"delete_account",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// destroy_user => /profile(.:format)
  // function(options)
  destroy_user_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"profile",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// destroy_user_session => /users/sign_out(.:format)
  // function(options)
  destroy_user_session_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"sign_out",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// discount_code => /discount_codes/:coupon(.:format)
  // function(coupon, options)
  discount_code_path: Utils.route([["coupon",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"discount_codes",false],[2,[7,"/",false],[2,[3,"coupon",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// districts => /districts(.:format)
  // function(options)
  districts_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"districts",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// edit_admin_discount_code => /admin/discount_codes/:id/edit(.:format)
  // function(id, options)
  edit_admin_discount_code_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"discount_codes",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_admin_district => /admin/districts/:id/edit(.:format)
  // function(id, options)
  edit_admin_district_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"districts",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_admin_domain => /admin/domains/:id/edit(.:format)
  // function(id, options)
  edit_admin_domain_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"domains",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_admin_ed_prep_program => /admin/ed_prep_programs/:id/edit(.:format)
  // function(id, options)
  edit_admin_ed_prep_program_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"ed_prep_programs",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_admin_family => /admin/families/:id/edit(.:format)
  // function(id, options)
  edit_admin_family_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"families",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_admin_group => /admin/groups/:id/edit(.:format)
  // function(id, options)
  edit_admin_group_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"groups",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_admin_group_group_user => /admin/groups/:group_id/group_users/:id/edit(.:format)
  // function(group_id, id, options)
  edit_admin_group_group_user_path: Utils.route([["group_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"groups",false],[2,[7,"/",false],[2,[3,"group_id",false],[2,[7,"/",false],[2,[6,"group_users",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// edit_admin_school => /admin/schools/:id/edit(.:format)
  // function(id, options)
  edit_admin_school_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"schools",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_admin_subscription => /admin/subscriptions/:id/edit(.:format)
  // function(id, options)
  edit_admin_subscription_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"subscriptions",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_admin_subscription_plan => /admin/subscription_plans/:id/edit(.:format)
  // function(id, options)
  edit_admin_subscription_plan_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"subscription_plans",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_admin_team => /admin/teams/:id/edit(.:format)
  // function(id, options)
  edit_admin_team_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"teams",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_admin_technique => /admin/techniques/:id/edit(.:format)
  // function(id, options)
  edit_admin_technique_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"techniques",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_admin_training => /admin/trainings/:id/edit(.:format)
  // function(id, options)
  edit_admin_training_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"trainings",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_admin_user => /admin/users/:id/edit(.:format)
  // function(id, options)
  edit_admin_user_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_group_group_user => /groups/:group_id/group_users/:id/edit(.:format)
  // function(group_id, id, options)
  edit_group_group_user_path: Utils.route([["group_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"groups",false],[2,[7,"/",false],[2,[3,"group_id",false],[2,[7,"/",false],[2,[6,"group_users",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_member => /members/:id/edit(.:format)
  // function(id, options)
  edit_member_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"members",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_profile => /profile/edit(.:format)
  // function(options)
  edit_profile_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"profile",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// edit_rails_conductor_inbound_email => /rails/conductor/action_mailbox/inbound_emails/:id/edit(.:format)
  // function(id, options)
  edit_rails_conductor_inbound_email_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"conductor",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// edit_team => /teams/:id/edit(.:format)
  // function(id, options)
  edit_team_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"teams",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_team_membership => /team_memberships/:id/edit(.:format)
  // function(id, options)
  edit_team_membership_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"team_memberships",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_user_password => /users/password/edit(.:format)
  // function(options)
  edit_user_password_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"password",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_user_registration => /users/edit(.:format)
  // function(options)
  edit_user_registration_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// email_approval_request => /users/email_approval_request(.:format)
  // function(options)
  email_approval_request_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"email_approval_request",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// ensure_registration_email => /users/ensure_email(.:format)
  // function(options)
  ensure_registration_email_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"ensure_email",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// export_admin_subscriptions => /admin/subscriptions/export(.:format)
  // function(options)
  export_admin_subscriptions_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"subscriptions",false],[2,[7,"/",false],[2,[6,"export",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// export_admin_users => /admin/users/export(.:format)
  // function(options)
  export_admin_users_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"export",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// export_user => /profile/export(.:format)
  // function(options)
  export_user_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"profile",false],[2,[7,"/",false],[2,[6,"export",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// export_users_admin_organization => /admin/organizations/:id/export_users(.:format)
  // function(id, options)
  export_users_admin_organization_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"organizations",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"export_users",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// generate_braintree_token => /users/registrations/braintree_token(.:format)
  // function(options)
  generate_braintree_token_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"registrations",false],[2,[7,"/",false],[2,[6,"braintree_token",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// group => /groups/:id(.:format)
  // function(id, options)
  group_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"groups",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// group_admin_report => /admin/report/groups/:id(.:format)
  // function(id, options)
  group_admin_report_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"report",false],[2,[7,"/",false],[2,[6,"groups",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// group_group_user => /groups/:group_id/group_users/:id(.:format)
  // function(group_id, id, options)
  group_group_user_path: Utils.route([["group_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"groups",false],[2,[7,"/",false],[2,[3,"group_id",false],[2,[7,"/",false],[2,[6,"group_users",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// group_group_users => /groups/:group_id/group_users(.:format)
  // function(group_id, options)
  group_group_users_path: Utils.route([["group_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"groups",false],[2,[7,"/",false],[2,[3,"group_id",false],[2,[7,"/",false],[2,[6,"group_users",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// group_imports => /group_imports(.:format)
  // function(options)
  group_imports_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"group_imports",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// groups => /groups(.:format)
  // function(options)
  groups_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"groups",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// impersonate_admin_user => /admin/users/:id/impersonate(.:format)
  // function(id, options)
  impersonate_admin_user_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"impersonate",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// import_team => /teams/:id/import(.:format)
  // function(id, options)
  import_team_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"teams",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"import",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// invitation => /invitations/:id(.:format)
  // function(id, options)
  invitation_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"invitations",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// invitations => /invitations(.:format)
  // function(options)
  invitations_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"invitations",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// invoice => /invoices/:id(.:format)
  // function(id, options)
  invoice_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"invoices",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// invoices => /invoices(.:format)
  // function(options)
  invoices_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"invoices",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// member => /members/:id(.:format)
  // function(id, options)
  member_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"members",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// modules_member => /members/:id/modules(.:format)
  // function(id, options)
  modules_member_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"members",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"modules",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// modules_organization => /organization/modules(.:format)
  // function(options)
  modules_organization_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"organization",false],[2,[7,"/",false],[2,[6,"modules",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// modules_team => /teams/:id/modules(.:format)
  // function(id, options)
  modules_team_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"teams",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"modules",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// my_progress => /my_progress(.:format)
  // function(options)
  my_progress_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"my_progress",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// new_admin_discount_code => /admin/discount_codes/new(.:format)
  // function(options)
  new_admin_discount_code_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"discount_codes",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_admin_district => /admin/districts/new(.:format)
  // function(options)
  new_admin_district_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"districts",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_admin_domain => /admin/domains/new(.:format)
  // function(options)
  new_admin_domain_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"domains",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_admin_ed_prep_program => /admin/ed_prep_programs/new(.:format)
  // function(options)
  new_admin_ed_prep_program_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"ed_prep_programs",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_admin_family => /admin/families/new(.:format)
  // function(options)
  new_admin_family_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"families",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_admin_group => /admin/groups/new(.:format)
  // function(options)
  new_admin_group_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"groups",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_admin_group_group_user => /admin/groups/:group_id/group_users/new(.:format)
  // function(group_id, options)
  new_admin_group_group_user_path: Utils.route([["group_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"groups",false],[2,[7,"/",false],[2,[3,"group_id",false],[2,[7,"/",false],[2,[6,"group_users",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// new_admin_school => /admin/schools/new(.:format)
  // function(options)
  new_admin_school_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"schools",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_admin_subscription_plan => /admin/subscription_plans/new(.:format)
  // function(options)
  new_admin_subscription_plan_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"subscription_plans",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_admin_team => /admin/teams/new(.:format)
  // function(options)
  new_admin_team_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"teams",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_admin_technique => /admin/techniques/new(.:format)
  // function(options)
  new_admin_technique_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"techniques",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_admin_training => /admin/trainings/new(.:format)
  // function(options)
  new_admin_training_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"trainings",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_admin_user => /admin/users/new(.:format)
  // function(options)
  new_admin_user_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_admin_users_import => /admin/users/import/new(.:format)
  // function(options)
  new_admin_users_import_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"import",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_group_group_user => /groups/:group_id/group_users/new(.:format)
  // function(group_id, options)
  new_group_group_user_path: Utils.route([["group_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"groups",false],[2,[7,"/",false],[2,[3,"group_id",false],[2,[7,"/",false],[2,[6,"group_users",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_group_import => /group_imports/new(.:format)
  // function(options)
  new_group_import_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"group_imports",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_invitation => /invitations/new(.:format)
  // function(options)
  new_invitation_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"invitations",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_rails_conductor_inbound_email => /rails/conductor/action_mailbox/inbound_emails/new(.:format)
  // function(options)
  new_rails_conductor_inbound_email_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"conductor",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// new_rails_conductor_inbound_email_source => /rails/conductor/action_mailbox/inbound_emails/sources/new(.:format)
  // function(options)
  new_rails_conductor_inbound_email_source_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"conductor",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[2,[7,"/",false],[2,[6,"sources",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// new_subscription => /subscriptions/new(.:format)
  // function(options)
  new_subscription_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"subscriptions",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_team => /teams/new(.:format)
  // function(options)
  new_team_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"teams",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_team_membership => /team_memberships/new(.:format)
  // function(options)
  new_team_membership_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"team_memberships",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_user_confirmation => /users/confirmation/new(.:format)
  // function(options)
  new_user_confirmation_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"confirmation",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_user_invitation => /users/invitation/new(.:format)
  // function(options)
  new_user_invitation_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"invitation",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_user_password => /users/password/new(.:format)
  // function(options)
  new_user_password_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"password",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_user_registration => /users/sign_up(.:format)
  // function(options)
  new_user_registration_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"sign_up",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_user_session => /users/sign_in(.:format)
  // function(options)
  new_user_session_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"sign_in",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// no_group_admin_report => /admin/report/groups(.:format)
  // function(options)
  no_group_admin_report_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"report",false],[2,[7,"/",false],[2,[6,"groups",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// onboard => /onboard(.:format)
  // function(options)
  onboard_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"onboard",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// onboard_update_profile => /onboard(.:format)
  // function(options)
  onboard_update_profile_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"onboard",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// organization_setup => /organization_setup(.:format)
  // function(options)
  organization_setup_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"organization_setup",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// organization_setup_add_coaches => /organization_setup/add_coaches(.:format)
  // function(options)
  organization_setup_add_coaches_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"organization_setup",false],[2,[7,"/",false],[2,[6,"add_coaches",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// organization_setup_add_managers => /organization_setup/add_managers(.:format)
  // function(options)
  organization_setup_add_managers_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"organization_setup",false],[2,[7,"/",false],[2,[6,"add_managers",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// organization_setup_add_teachers => /organization_setup/add_teachers(.:format)
  // function(options)
  organization_setup_add_teachers_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"organization_setup",false],[2,[7,"/",false],[2,[6,"add_teachers",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// organization_setup_create_teams => /organization_setup/create_teams(.:format)
  // function(options)
  organization_setup_create_teams_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"organization_setup",false],[2,[7,"/",false],[2,[6,"create_teams",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// organization_setup_step_1 => /organization_setup/1(.:format)
  // function(options)
  organization_setup_step_1_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"organization_setup",false],[2,[7,"/",false],[2,[6,"1",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// organization_setup_step_2 => /organization_setup/2(.:format)
  // function(options)
  organization_setup_step_2_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"organization_setup",false],[2,[7,"/",false],[2,[6,"2",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// organization_setup_step_3 => /organization_setup/3(.:format)
  // function(options)
  organization_setup_step_3_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"organization_setup",false],[2,[7,"/",false],[2,[6,"3",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// organization_setup_step_4 => /organization_setup/4(.:format)
  // function(options)
  organization_setup_step_4_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"organization_setup",false],[2,[7,"/",false],[2,[6,"4",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// pages_get_better_faster => /pages/get-better-faster(.:format)
  // function(options)
  pages_get_better_faster_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"pages",false],[2,[7,"/",false],[2,[6,"get-better-faster",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// pages_privacy => /pages/privacy(.:format)
  // function(options)
  pages_privacy_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"pages",false],[2,[7,"/",false],[2,[6,"privacy",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// pages_terms => /pages/terms(.:format)
  // function(options)
  pages_terms_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"pages",false],[2,[7,"/",false],[2,[6,"terms",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// practice => /practice/:share_key(.:format)
  // function(share_key, options)
  practice_path: Utils.route([["share_key",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"practice",false],[2,[7,"/",false],[2,[3,"share_key",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// profile_password => /profile/password(.:format)
  // function(options)
  profile_password_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"profile",false],[2,[7,"/",false],[2,[6,"password",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// queue_admin_invoices => /admin/invoices/queue(.:format)
  // function(options)
  queue_admin_invoices_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"invoices",false],[2,[7,"/",false],[2,[6,"queue",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// rails_blob_representation => /rails/active_storage/representations/redirect/:signed_blob_id/:variation_key/*filename(.:format)
  // function(signed_blob_id, variation_key, filename, options)
  rails_blob_representation_path: Utils.route([["signed_blob_id",true],["variation_key",true],["filename",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"active_storage",false],[2,[7,"/",false],[2,[6,"representations",false],[2,[7,"/",false],[2,[6,"redirect",false],[2,[7,"/",false],[2,[3,"signed_blob_id",false],[2,[7,"/",false],[2,[3,"variation_key",false],[2,[7,"/",false],[2,[5,[3,"filename",false],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]),
// rails_blob_representation_proxy => /rails/active_storage/representations/proxy/:signed_blob_id/:variation_key/*filename(.:format)
  // function(signed_blob_id, variation_key, filename, options)
  rails_blob_representation_proxy_path: Utils.route([["signed_blob_id",true],["variation_key",true],["filename",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"active_storage",false],[2,[7,"/",false],[2,[6,"representations",false],[2,[7,"/",false],[2,[6,"proxy",false],[2,[7,"/",false],[2,[3,"signed_blob_id",false],[2,[7,"/",false],[2,[3,"variation_key",false],[2,[7,"/",false],[2,[5,[3,"filename",false],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]),
// rails_conductor_inbound_email => /rails/conductor/action_mailbox/inbound_emails/:id(.:format)
  // function(id, options)
  rails_conductor_inbound_email_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"conductor",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// rails_conductor_inbound_email_incinerate => /rails/conductor/action_mailbox/:inbound_email_id/incinerate(.:format)
  // function(inbound_email_id, options)
  rails_conductor_inbound_email_incinerate_path: Utils.route([["inbound_email_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"conductor",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[3,"inbound_email_id",false],[2,[7,"/",false],[2,[6,"incinerate",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// rails_conductor_inbound_email_reroute => /rails/conductor/action_mailbox/:inbound_email_id/reroute(.:format)
  // function(inbound_email_id, options)
  rails_conductor_inbound_email_reroute_path: Utils.route([["inbound_email_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"conductor",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[3,"inbound_email_id",false],[2,[7,"/",false],[2,[6,"reroute",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// rails_conductor_inbound_email_sources => /rails/conductor/action_mailbox/inbound_emails/sources(.:format)
  // function(options)
  rails_conductor_inbound_email_sources_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"conductor",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[2,[7,"/",false],[2,[6,"sources",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// rails_conductor_inbound_emails => /rails/conductor/action_mailbox/inbound_emails(.:format)
  // function(options)
  rails_conductor_inbound_emails_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"conductor",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// rails_direct_uploads => /rails/active_storage/direct_uploads(.:format)
  // function(options)
  rails_direct_uploads_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"active_storage",false],[2,[7,"/",false],[2,[6,"direct_uploads",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// rails_disk_service => /rails/active_storage/disk/:encoded_key/*filename(.:format)
  // function(encoded_key, filename, options)
  rails_disk_service_path: Utils.route([["encoded_key",true],["filename",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"active_storage",false],[2,[7,"/",false],[2,[6,"disk",false],[2,[7,"/",false],[2,[3,"encoded_key",false],[2,[7,"/",false],[2,[5,[3,"filename",false],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// rails_info => /rails/info(.:format)
  // function(options)
  rails_info_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"info",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// rails_info_properties => /rails/info/properties(.:format)
  // function(options)
  rails_info_properties_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"info",false],[2,[7,"/",false],[2,[6,"properties",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// rails_info_routes => /rails/info/routes(.:format)
  // function(options)
  rails_info_routes_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"info",false],[2,[7,"/",false],[2,[6,"routes",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// rails_mailers => /rails/mailers(.:format)
  // function(options)
  rails_mailers_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"mailers",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// rails_mailgun_inbound_emails => /rails/action_mailbox/mailgun/inbound_emails/mime(.:format)
  // function(options)
  rails_mailgun_inbound_emails_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"mailgun",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[2,[7,"/",false],[2,[6,"mime",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// rails_mandrill_inbound_emails => /rails/action_mailbox/mandrill/inbound_emails(.:format)
  // function(options)
  rails_mandrill_inbound_emails_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"mandrill",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// rails_mandrill_inbound_health_check => /rails/action_mailbox/mandrill/inbound_emails(.:format)
  // function(options)
  rails_mandrill_inbound_health_check_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"mandrill",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// rails_postmark_inbound_emails => /rails/action_mailbox/postmark/inbound_emails(.:format)
  // function(options)
  rails_postmark_inbound_emails_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"postmark",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// rails_relay_inbound_emails => /rails/action_mailbox/relay/inbound_emails(.:format)
  // function(options)
  rails_relay_inbound_emails_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"relay",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// rails_sendgrid_inbound_emails => /rails/action_mailbox/sendgrid/inbound_emails(.:format)
  // function(options)
  rails_sendgrid_inbound_emails_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"sendgrid",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// rails_service_blob => /rails/active_storage/blobs/redirect/:signed_id/*filename(.:format)
  // function(signed_id, filename, options)
  rails_service_blob_path: Utils.route([["signed_id",true],["filename",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"active_storage",false],[2,[7,"/",false],[2,[6,"blobs",false],[2,[7,"/",false],[2,[6,"redirect",false],[2,[7,"/",false],[2,[3,"signed_id",false],[2,[7,"/",false],[2,[5,[3,"filename",false],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// rails_service_blob_proxy => /rails/active_storage/blobs/proxy/:signed_id/*filename(.:format)
  // function(signed_id, filename, options)
  rails_service_blob_proxy_path: Utils.route([["signed_id",true],["filename",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"active_storage",false],[2,[7,"/",false],[2,[6,"blobs",false],[2,[7,"/",false],[2,[6,"proxy",false],[2,[7,"/",false],[2,[3,"signed_id",false],[2,[7,"/",false],[2,[5,[3,"filename",false],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// related_remote_modules_admin_trainings => /admin/trainings/related_remote_modules(.:format)
  // function(options)
  related_remote_modules_admin_trainings_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"trainings",false],[2,[7,"/",false],[2,[6,"related_remote_modules",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// remove_user_invitation => /users/invitation/remove(.:format)
  // function(options)
  remove_user_invitation_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"invitation",false],[2,[7,"/",false],[2,[6,"remove",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// renewal_subscriptions => /subscriptions/renewal(.:format)
  // function(options)
  renewal_subscriptions_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"subscriptions",false],[2,[7,"/",false],[2,[6,"renewal",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// report_group => /groups/:id/report(.:format)
  // function(id, options)
  report_group_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"groups",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"report",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// request_demo => /users/request_demo(.:format)
  // function(options)
  request_demo_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"request_demo",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// resend_confirmation => /profile/resend_confirmation(.:format)
  // function(options)
  resend_confirmation_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"profile",false],[2,[7,"/",false],[2,[6,"resend_confirmation",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// resend_invitation => /invitations/:id/resend(.:format)
  // function(id, options)
  resend_invitation_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"invitations",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"resend",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// reset_assignment => /assignment/reset(.:format)
  // function(options)
  reset_assignment_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"assignment",false],[2,[7,"/",false],[2,[6,"reset",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// reshare_training_result => /training_results/:id/reshare(.:format)
  // function(id, options)
  reshare_training_result_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"training_results",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"reshare",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// resources => /resources(.:format)
  // function(options)
  resources_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"resources",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// review_subscriptions => /subscriptions/review(.:format)
  // function(options)
  review_subscriptions_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"subscriptions",false],[2,[7,"/",false],[2,[6,"review",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// root => /
  // function(options)
  root_path: Utils.route([], {}, [7,"/",false]),
// schools => /schools(.:format)
  // function(options)
  schools_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"schools",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// share_training => /trainings/:id/share(.:format)
  // function(id, options)
  share_training_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"trainings",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"share",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// sidekiq_web => /sidekiq
  // function(options)
  sidekiq_web_path: Utils.route([], {}, [2,[7,"/",false],[6,"sidekiq",false]]),
// stop_impersonating_admin_users => /admin/users/stop_impersonating(.:format)
  // function(options)
  stop_impersonating_admin_users_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"stop_impersonating",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// subscriptions => /subscriptions(.:format)
  // function(options)
  subscriptions_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"subscriptions",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// team => /teams/:id(.:format)
  // function(id, options)
  team_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"teams",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// team_membership => /team_memberships/:id(.:format)
  // function(id, options)
  team_membership_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"team_memberships",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// team_memberships => /team_memberships(.:format)
  // function(options)
  team_memberships_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"team_memberships",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// teams => /teams(.:format)
  // function(options)
  teams_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"teams",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// technique => /techniques/:id(.:format)
  // function(id, options)
  technique_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"techniques",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// track_progress => /my_progress/track(.:format)
  // function(options)
  track_progress_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"my_progress",false],[2,[7,"/",false],[2,[6,"track",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// track_training_stats => /training_stats/track(.:format)
  // function(options)
  track_training_stats_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"training_stats",false],[2,[7,"/",false],[2,[6,"track",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// training => /trainings/:id(.:format)
  // function(id, options)
  training_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"trainings",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// training_result => /training_results/:id(.:format)
  // function(id, options)
  training_result_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"training_results",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// training_results => /training_results(.:format)
  // function(options)
  training_results_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"training_results",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// update_assignment => /assignment(.:format)
  // function(options)
  update_assignment_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"assignment",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// update_rails_disk_service => /rails/active_storage/disk/:encoded_token(.:format)
  // function(encoded_token, options)
  update_rails_disk_service_path: Utils.route([["encoded_token",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"active_storage",false],[2,[7,"/",false],[2,[6,"disk",false],[2,[7,"/",false],[2,[3,"encoded_token",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// update_status_admin_invoice => /admin/invoices/:id/update_status(.:format)
  // function(id, options)
  update_status_admin_invoice_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"invoices",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"update_status",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// upgrade_subscriptions => /subscriptions/upgrade(.:format)
  // function(options)
  upgrade_subscriptions_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"subscriptions",false],[2,[7,"/",false],[2,[6,"upgrade",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// user_admin_report => /admin/report/users/:id(.:format)
  // function(id, options)
  user_admin_report_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"report",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// user_confirmation => /users/confirmation(.:format)
  // function(options)
  user_confirmation_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"confirmation",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// user_invitation => /users/invitation(.:format)
  // function(options)
  user_invitation_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"invitation",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// user_password => /users/password(.:format)
  // function(options)
  user_password_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"password",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// user_profile => /profile(.:format)
  // function(options)
  user_profile_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"profile",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// user_registration => /users(.:format)
  // function(options)
  user_registration_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// user_session => /users/sign_in(.:format)
  // function(options)
  user_session_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"sign_in",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// users_sign_out => /users/sign_out(.:format)
  // function(options)
  users_sign_out_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"sign_out",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// video => /videos/:id(.:format)
  // function(id, options)
  video_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"videos",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// video_player => /video_player(.:format)
  // function(options)
  video_player_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"video_player",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// videos => /videos(.:format)
  // function(options)
  videos_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"videos",false],[1,[2,[8,".",false],[3,"format",false]],false]]])}
;
      routes.configure = function(config) {
        return Utils.configure(config);
      };
      routes.config = function() {
        return Utils.config();
      };
      Object.defineProperty(routes, 'defaults', {
        get: function() {
          throw new Error("Namespace" + ".defaults is removed. Use " + "Namespace" + ".configure() instead.");
        },
        set: function(value) {}
      });
      routes.default_serializer = function(object, prefix) {
        return Utils.default_serializer(object, prefix);
      };
      return Utils.namespace(root, "Namespace", routes);
    }
  };

  result = Utils.make();

  if (typeof define === "function" && define.amd) {
    define([], function() {
      return result;
    });
  }

  return result;

}).call(this);

}.call(routes));

export default routes.Namespace;
